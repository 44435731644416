import { render, staticRenderFns } from "./CartaoPaid.vue?vue&type=template&id=a387c6a4&scoped=true&"
import script from "./CartaoPaid.vue?vue&type=script&lang=js&"
export * from "./CartaoPaid.vue?vue&type=script&lang=js&"
import style0 from "./CartaoPaid.vue?vue&type=style&index=0&id=a387c6a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a387c6a4",
  null
  
)

export default component.exports