<template>
	<b-modal
		active
		:can-cancel="['x']"
		width="526"
		@close="$emit('close')"
	>
		<div class="card">
			<div class="card-content">
				<div class="has-text-centered">
					<Icon
						file="pagamento/cartao_sucesso"
						size="210:203"
					/>
				</div>

				<h3 class="title is-3 has-text-centered">
					Dados salvos com sucesso!
				</h3>

				<p class="paragraph mb-5 has-text-centered">
					Os dados de seu cartão foram salvos, facilitando seus próximos pagamentos.
				</p>

				<div class="columns is-gapless pt-5 is-vcentered is-centered">
					<div class="column has-text-centered">
						<b-button
							type="is-primary"
							rounded
							outlined
							@click="$emit('close')"
						>
							Continuar
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import Icon from '@components/Icon';

export default {
	name: 'CartaoModalSaved',

	components: {
		Icon,
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.modal-content {
		max-width: 526px !important;
		width: calc(100% - 20px);

		.card-content {
			padding: 40px;
		}
	}
}
</style>
