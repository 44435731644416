<template>
	<div class="button-wrapper">
		<button
			:class="{
				'active': isCardListSelected,
				'disabled': isCardListDisabled,
			}"
			:disabled="isCardListDisabled"
			@click="selectTab('cardList', $event)"
		>
			Usar cartão salvo
		</button>

		<div
			:class="{
				'spacer': true,
				'inactive-left': isCardFormSelected,
				'inactive-right': isCardListSelected,
			}"
		/>

		<button
			:class="{
				'active': isCardFormSelected,
			}"
			@click="selectTab('cardForm', $event)"
		>
			Inserir dados do cartão
		</button>
	</div>
</template>

<script>
export default {
	name: 'CartaoTabs',

	props: {
		hasSavedCard: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			selected: this.hasSavedCard
				? 'cardList'
				: 'cardForm',
		};
	},

	computed: {
		isCardListSelected() {
			return this.selected == 'cardList';
		},

		isCardFormSelected() {
			return this.selected == 'cardForm';
		},

		isCardListDisabled() {
			return this.hasSavedCard == false;
		},
	},

	created() {
		this.$emit(this.selected);
	},

	methods: {
		selectTab(tab, event) {
			this.selected = tab;

			this.$emit(tab);

			event.target.offsetParent.scrollLeft = event.target.offsetLeft;
		},
	},
}
</script>

<style lang="scss" scoped>
.button-wrapper {
	height: 29px;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
	align-items: flex-end;
	scrollbar-width: none;
	position: relative;

	* {
		position: relative;
		bottom: 0;
	}
}

button {
	flex-shrink: 0;
	margin: 0;
	padding: 6px 19px 1px;
	border: 0 solid #000;
	color: #999999;
	background-color: #E9EBEE;
	font-weight: 700;
	line-height: 16px;
	font-size: 16px;
	font-family: 'Nunito Sans';
	cursor: pointer;

	&:hover {
		color: #525252;
	}

	&.active {
		border-color: #D9D9D9;
		padding-top: 10px;
		color: #525252;
		background-color: #FFF;

		&.active {
			border-top-width: 2px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			&:first-child {
				border-left-width: 2px;
				border-bottom-left-radius: 8px;
			}

			&:last-child {
				border-right-width: 2px;
				border-bottom-right-radius: 8px;
			}
		}
	}

	&.disabled {
		cursor: not-allowed !important;

		&:hover {
			color: #999999 !important;
		}
	}
}

.spacer {
	flex-grow: 1;
	min-width: 8px;
	height: 23px;
	background-color: #E9EBEE;

	&.inactive-right {
		border-top-left-radius: 8px;
	}

	&.inactive-left {
		border-top-right-radius: 8px;
	}
}
</style>
