<template>
	<div
		class="cartao-paid-wrapper"
	>
		<b-button
			type="is-primary"
			rounded
			@click="$emit('back')"
		>
			Voltar ao menu de Pagamentos
		</b-button>
	</div>
</template>

<script>
export default {
	name: 'CartaoPaid',

	mounted() {
		const html = `
<span style="display: inline-flex; align-items: center; gap: 1rem; overflow: hidden;">
	<i class="mdi mdi-check-circle mdi-36px" style="line-height: 36px; height: 36px"></i>
	Pagamento efetuado com sucesso!
</span>`;
		this.$buefy.notification.open({
			message: html,
			type: 'is-success',
			position: 'is-top',
			autoClose: true,
			indefinite: false,
			closable: false,
			duration: 10000,
		});
	},
}
</script>

<style lang="scss" scoped>
.cartao-paid-wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	text-align: center;
	height: 100%;
}
</style>
