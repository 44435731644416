<template>
	<div class="column is-6-desktop is-12 is-flex is-flex-direction-column center-on-mobile">
		<div class="pt-3">
			<span class="is-size-6 has-text-weight-bold">
				Mensalidade
			</span>
		</div>

		<p class="is-size-4 has-text-weight-bold pb-4">
			R${{ valorTitulo }}
		</p>

		<p class="is-size-6">
			Vencimento: <strong>{{ dataVencimento }}</strong>
		</p>

		<p class="is-size-6">
			Curso: <strong>{{ cursoNome }}</strong>
		</p>

		<p class="is-size-6">
			Período letivo: <strong>{{ periodoLetivo }}</strong>
		</p>

		<p
			v-if="isMensalidade"
			class="is-size-6"
		>
			Mês referência: <strong>{{ mesReferencia }}</strong>
		</p>

		<p
			v-else
			class="is-size-6"
		>
			Tipo: <strong>{{ docTipo }}</strong>
		</p>
	</div>
</template>

<script>
import moment from 'moment';

const parseDate = date => {
	return moment(date).format('DD/MM/YYYY');
};

const capitalizeWords = str => {
	if (!str) {
		return '';
	}

	return str.toLowerCase()
		.replaceAll(
			/(\w)([\w]{2,})/g,
			(_, first, rest) => first.toUpperCase() + rest
		);
}

export default {
	name: 'DadosPagamento',

	props: {
		fatura: {
			type: Object,
			default: () => ({}),
		},
	},

	computed: {
		valorTitulo() {
			return this.fatura?.valor_titulo;
		},

		dataVencimento() {
			if (!this.valorTitulo) {
				return null;
			}

			return parseDate(
				this.fatura.data_vencimento_regerado
					? this.fatura.data_vencimento_regerado
					: this.fatura.data_vencimento
			);
		},

		cursoNome() {
			return this.fatura?.curs_nome;
		},

		periodoLetivo() {
			return this.fatura?.periodo_letivo;
		},

		isMensalidade() {
			return this.fatura?.doc_tipo?.toLowerCase() == 'm';
		},

		mesReferencia() {
			return this.fatura?.mes_referencia.replace('/', ' / ');
		},

		docTipo() {
			return capitalizeWords(this.fatura?.doc_tipo_parse);
		},
	}
}
</script>

<style scoped>
@media (max-width: 1020px) {
  .center-on-mobile {
      justify-content: center;
  }
}
</style>
