<template>
	<div>
		<div class="column is-12">
			<UIInput
				id="card-list-card-number"
				v-model="cardNumber"
				label="Número do cartão"
				placeholder="Número do cartão"
				type="creditcard"
				readonly
				:no-style="['readonly']"
				:credit-card-icon="cardIcon"
				:show-credit-card-icon="true"
			/>
		</div>

		<div class="column is-12">
			<b-field
				grouped
				position="is-right"
			>
				<b-switch
					v-model="formRecurrence"
					type="is-primary"
					rounded
					@click.native.capture.prevent="formRecurrence = !formRecurrence"
				>
					Ativar recorrência de pagamento
				</b-switch>

				<b-icon
					icon="help-circle-outline is-clickable"
					@click.native="$emit('recurrence', 'info')"
				/>
			</b-field>
		</div>

		<div class="column is-12 is-centered">
			<b-button
				type="is-primary"
				rounded
				@click="pay"
			>
				Efetuar Pagamento
			</b-button>
		</div>

		<div class="column is-12 is-centered">
			<b-button
				type="is-danger"
				rounded
				outlined
				@click="$emit('delete')"
			>
				Remover cartão
			</b-button>
		</div>

		<div
			v-if="showBackButton"
			class="column is-12 is-centered"
		>
			<b-button
				type="is-primary"
				rounded
				outlined
				@click="$emit('back')"
			>
				Voltar
			</b-button>
		</div>
	</div>
</template>

<script>
import UIInput from '@components/uiinput';

export default {
	name: 'CartaoList',

	components: {
		UIInput
	},

	props: {
		cardIcon: {
			type: String,
			required: true,
		},

		recurrence: {
			type: Boolean,
			default: false,
		},

		hash: {
			type: [ String, Number ],
			required: true,
		},

		lastDigits: {
			type: [ String, Number ],
			required: true,
		},

		showBackButton: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		cardNumber() {
			if (this.cardIcon.match(/american_express|diners/)) {
				return '•••• •••• ••' + this.lastDigits;
			}

			return '•••• •••• •••• ' + this.lastDigits;
		},

		formRecurrence: {
			set(value) {
				if (value) {
					this.$emit('recurrence');
				} else {
					this.$emit('recurrence-disable');
				}
			},

			get() {
				return this.recurrence;
			},
		},
	},

	methods: {
		pay() {
			this.$emit(
				'pay',
				{
					cardIcon: this.cardIcon,
					hash: this.hash,
					lastDigits: this.lastDigits,
				}
			);
		},
	},
}
</script>

<style lang="scss" scoped>
.column.is-centered {
	text-align: center;
}
</style>
