<template>
	<div>
		<div class="column is-12">
			<UIInput
				id="numeroCartao"
				v-model="formCartao.numero"
				v-mask="checkCardMask"
				label="Número do cartão"
				placeholder="Número do cartão"
				type="creditcard"
				:validation="$v.formCartao.numero"
				:credit-card-icon="creditCardIcon"
				:show-credit-card-icon="true"
				:error="{
					required: '*Campo obrigatório.',
					isValidCreditCard: '*Número do cartão inválido.',
				}"
			/>
		</div>

		<div class="column is-12">
			<UIInput
				id="nomeCartao"
				v-model="formCartao.nome"
				label="Nome no cartão"
				placeholder="Nome no cartão"
				:validation="$v.formCartao.nome"
				:error="{
					required: '*Campo obrigatório.',
				}"
			/>
		</div>

		<div class="column is-12">
			<UIInput
				id="validadeCartao1"
				v-model="formCartao.validade"
				v-mask="'##/##'"
				label="Validade do cartão (mm/aa)"
				placeholder="Validade do cartão (mm/aa)"
				:validation="$v.formCartao.validade"
				type="creditcard"
				:error="{
					isValidCardExpiration: isMobile ? '*Data de validade no formato mm/aa' : '*A data de validade deve conter mês e ano no formato mm/aa',
					required: '*Campo obrigatório.',
				}"
			/>
		</div>

		<div class="column is-12">
			<UIInput
				id="cvvCartao"
				v-model="formCartao.cvv"
				v-mask="'####'"
				label="Código de segurança"
				placeholder="Código de segurança"
				:validation="$v.formCartao.cvv"
				:error="{
					required: '*Campo obrigatório.',
					minLength: '*CVV inválido.',
				}"
			/>
		</div>

		<!--
		<div class="column is-12">
			<UIInput
				id="parcelasCartao"
				type="select"
				:options="parcelas"
				label="Parcelamento"
				placeholder="Parcelamento"
				v-model="formCartao.parcelas"
				:validation="$v.formCartao.parcelas"
				:error="{
					required: '*Campo obrigatório.',
				}"
				required
			/>
		</div>
		-->

		<div class="column is-12">
			<b-field
				grouped
				position="is-right"
			>
				<b-checkbox
					v-model="formCartao.salvarCartao"
					type="is-primary"
					@input="checkSaveCard"
				>
					Salvar os dados deste cartão
				</b-checkbox>
			</b-field>
		</div>

		<div class="column is-12">
			<b-field
				grouped
				position="is-right"
			>
				<b-switch
					v-model="formRecurrence"
					type="is-primary"
					rounded
					:disabled="!formCartao.salvarCartao"
					@click.native.capture.prevent="updateRecurrence"
				>
					Ativar recorrência de pagamento
				</b-switch>

				<b-icon
					icon="help-circle-outline is-clickable"
					@click.native="$emit('recurrence', 'info')"
				/>
			</b-field>
		</div>

		<div class="column is-12">
			<b-button
				type="is-primary"
				rounded
				expanded
				@click="pay"
			>
				Efetuar Pagamento
			</b-button>
		</div>

		<div
			v-if="showBackButton"
			class="column is-12 btn-voltar"
		>
			<b-button
				type="is-primary"
				rounded
				outlined
				expanded
				@click="$emit('back')"
			>
				Voltar
			</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import UIInput from '@components/uiinput';
import { checkCreditCardType } from '@commons/helpers';
import { isValidCreditCard, isValidCardExpiration } from '@commons/validations';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';


export default {
	name: 'CartaoForm',

	components: {
		UIInput
	},

	props: {
		showBackButton: {
			type: Boolean,
			default: true,
		},

		hasSavedCard: {
			type: Boolean,
			default: false,
		},

		recurrence: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			loading: false,
			formCartao: {
				numero: '',
				nome: '',
				validade: '',
				cvv: '',
				parcelas: 1,
				salvarCartao: false,
			},
		}
	},

	computed: {
		checkCardMask() {
			const creditCardFlag = checkCreditCardType(this.formCartao.numero);

			if (creditCardFlag && (creditCardFlag.match(/american_express|diners/))) {
				return '#### ###### ######';
			}

			return '#### #### #### ####';
		},

		creditCardIcon() {
			if (isValidCreditCard(this.formCartao.numero)) {
				return checkCreditCardType(this.formCartao.numero);
			}

			return false;
		},

		...mapGetters('ui', [
			'isMobile'
		]),

		/*
		parcelas() {
			const parcelas = [];

			for (let i = 1; i <= 10; i++) {
				const valorParcela = this.fatura.valor_titulo / i;

				parcelas.push({
					label: `${i} X ${valorParcela.toFixed(2)}`,
					value: i
				});
			}

			return parcelas;
		},
		*/

		formRecurrence: {
			set(value) {
				if (value) {
					this.$emit('recurrence');
				} else {
					this.$emit('recurrence-disable');
				}
			},

			get() {
				return this.recurrence;
			},
		},
	},

	methods: {
		checkSaveCard() {
			if (this.hasSavedCard) {
				this.$store.dispatch(
					'ui/toast/openError',
					'Você já possui um cartão salvo. Remova o cartão já salvo, antes de inserir novas informações para pagamento'
				);

				this.$nextTick(() => {
					this.formCartao.salvarCartao = false;
				});
			} else if (this.formRecurrence) {
				this.formRecurrence = false;
			}
		},

		updateRecurrence() {
			if (!this.formCartao.salvarCartao) {
				return;
			}

			this.formRecurrence = !this.formRecurrence;
		},

		pay() {
			this.$v.formCartao.$touch();

			if (this.$v.formCartao.$pending || this.$v.formCartao.$error) {
				return;
			}

			this.$emit(
				'pay',
				{
					...this.formCartao,
					...{
						bandeira: this.creditCardIcon
					},
				}
			);
		},
	},

	validations: {
		formCartao: {
			numero: {
				required,
				isValidCreditCard,
			},
			nome: {
				required,
			},
			validade: {
				required,
				isValidCardExpiration,
			},
			cvv: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(4),
			},
			/*
			parcelas: {
				required,
			},
			*/
		},
	},
}
</script>

