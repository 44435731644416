<template>
	<transition name="fade">
		<div>
			<b-loading
				v-model="loading"
				:is-full-page="true"
				:can-cancel="false"
			/>

			<div
				class="px-3-mobile px-6-tablet px-3-desktop px-6-widescreen"
				:class="{ 'pt-3': !usingOnModal }"
			>
				<div
					class="columns is-desktop"
					:class="{ 'py-4': !usingOnModal }"
				>
					<DadosPagamento
						v-if="!usingOnModal"
						:fatura="fatura"
					/>

					<div
						v-if="!usingOnModal"
						class="column vertical-divider is-1-desktop is-hidden-touch"
					/>

					<Cartao
						class="column is-12 is-flex is-flex-direction-column"
						:class="{
							'is-5-desktop' : !usingOnModal
						}"
						:fatura="fatura"
						:emit-return="usingOnModal"
						:show-back-button="!usingOnModal"
						@loading="loading = $event"
						@retorno-pagamento="$emit('retorno-pagamento', $event)"
						@back="back"
					/>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import DadosPagamento from '@components/meus_pagamentos/pagamento/DadosPagamento';
import Cartao from '@components/meus_pagamentos/pagamento/Cartao';

export default {
	name: 'Pagamento',

	components: {
		DadosPagamento,
		Cartao,
	},

	props: {
		fatura: {
			type: Object,
			default: () => ({}),
		},

		usingOnModal: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			loading: false,
		};
	},

	created() {
		if (typeof this.fatura.doc_id === 'undefined') {
			this.back();
		}
	},

	beforeDestroy() {
		this.$store.commit('setFatura', {});
	},

	methods: {
		back() {
			this.$store.dispatch('pagamentos/todosPagamentos');

			this.$router.push({
				name: 'meus-pagamentos'
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@styles/_variables";

hr {
	background-color: #c2c2c2;
	height: 1px !important;
}

.payed {
	color: #37C36F;
}
</style>
