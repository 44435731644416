<template>
	<div>
		<CartaoTabs
			v-if="hasSavedCard && (view == 1 || view == 2)"
			:has-saved-card="hasSavedCard"
			@cardList="view = 1"
			@cardForm="view = 2"
		/>

		<div
			v-else-if="view == 2"
			class="column is-12"
		>
			<span class="is-size-6 has-text-weight-bold">Inserir dados do cartão</span>
		</div>

		<transition
			name="fade"
			mode="out-in"
		>
			<CartaoList
				v-if="view == 1"
				:card-icon="savedCard.cardIcon"
				:recurrence="savedCard.recurrence"
				:hash="savedCard.hash"
				:last-digits="savedCard.lastDigits"
				:show-back-button="showBackButton"
				@recurrence="showRecurrenceCartaoModal = $event || true"
				@recurrence-disable="shouldShowRecurrenceDisableCartaoModal"
				@pay="pay"
				@delete="showDeleteCardModal = true"
				@back="$emit('back')"
			/>

			<CartaoForm
				v-else-if="view == 2"
				:has-saved-card="hasSavedCard"
				:recurrence="savedCard.recurrence"
				:show-back-button="showBackButton"
				@recurrence="showRecurrenceCartaoModal = $event || true"
				@recurrence-disable="shouldShowRecurrenceDisableCartaoModal"
				@pay="pay"
				@back="$emit('back')"
			/>

			<CartaoPaid
				v-else-if="view == 3"
				@back="$emit('back')"
			/>
		</transition>

		<CartaoModalSaved
			v-if="showSavedCardModal"
			@close="showSavedCardModal = false"
		/>

		<CartaoModalDelete
			v-if="showDeleteCardModal"
			@confirm="deleteCard"
			@close="showDeleteCardModal = false"
		/>

		<CartaoModalRecurrence
			v-if="showRecurrenceCartaoModal"
			:is-info="showRecurrenceCartaoModal == 'info'"
			@confirm="enableRecurrence"
			@close="showRecurrenceCartaoModal = false"
		/>

		<CartaoModalRecurrenceDisable
			v-if="showRecurrenceDisableCartaoModal"
			@confirm="disableRecurrence"
			@close="showRecurrenceDisableCartaoModal = false"
		/>
	</div>
</template>

<script>
import http from '@commons/http';
import CartaoForm from '@components/meus_pagamentos/pagamento/CartaoForm';
import CartaoList from '@components/meus_pagamentos/pagamento/CartaoList';
import CartaoModalDelete from '@components/meus_pagamentos/pagamento/CartaoModalDelete';
import CartaoModalSaved from '@components/meus_pagamentos/pagamento/CartaoModalSaved';
import CartaoModalRecurrence from '@components/meus_pagamentos/pagamento/CartaoModalRecurrence';
import CartaoModalRecurrenceDisable from '@components/meus_pagamentos/pagamento/CartaoModalRecurrenceDisable';
import CartaoPaid from '@components/meus_pagamentos/pagamento/CartaoPaid';
import CartaoTabs from '@components/meus_pagamentos/pagamento/CartaoTabs';

export default {
	name: 'Cartao',

	components: {
		CartaoForm,
		CartaoList,
		CartaoModalDelete,
		CartaoModalSaved,
		CartaoModalRecurrence,
		CartaoModalRecurrenceDisable,
		CartaoPaid,
		CartaoTabs,
	},

	props: {
		fatura: {
			type: Object,
			required: true,
		},

		showBackButton: {
			type: Boolean,
			default: true,
		},

		emitReturn: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			view: 2,
			showSavedCardModal: false,
			showDeleteCardModal: false,
			showRecurrenceCartaoModal: false,
			showRecurrenceDisableCartaoModal: false,
			savedCard: {
				recurrence: false,
				hash: '',
				lastDigits: '',
				cardIcon: '',
			},
		};
	},

	computed: {
		hasSavedCard() {
			return this.savedCard.hash.length != 0;
		},
	},

	async created() {
		this.$emit('loading', true);

		try {
			const response = await http.get('/v1/aluno/pagamentos/dados-cartao-atual');

			if (response.status !== 200) {
				throw new Error('Invalid response');
			}

			this.savedCard.recurrence = response.data?.cartao?.alun_recorrencia_ativa;
			this.savedCard.hash = response.data?.cartao?.alun_hash_cartao_padrao;
			this.savedCard.lastDigits = response.data?.cartao?.alun_ultimos_digitos_cartao;
			this.savedCard.cardIcon = response.data?.cartao?.alun_bandeira_cartao;

			this.view = 1;
		} catch (error) {
			// console.error(error);
		}

		this.$emit('loading', false);
	},

	methods: {
		async pay(card) {
			if (card.hash) {
				return this.paySaved(card);
			}

			return this.payCard(card);
		},

		async payCard(card) {
			const data = {
				doc_ids: this.fatura.doc_id,
				matricula: this.$store.state.alunoMatricula,
				valor_total: this.fatura.valor_titulo.replace('.', '').replace(',', '.'),
				parcelas: 1, // cartao.parcelas
				num_cartao: card.numero.replace(/ /g,''),
				nome: card.nome,
				validade: card.validade,
				ccv: card.cvv,
				bandeira: card.bandeira,
				salvar_cartao: card.salvarCartao ? 'true' : 'false',
			};

			if (!this.hasSavedCard && this.savedCard.recurrence) {
				data.recorrencia = 'true';
			}

			return this.payAction(data, false, card.salvarCartao);
		},

		async paySaved(card) {
			const data = {
				doc_ids: this.fatura.doc_id,
				matricula: this.$store.state.alunoMatricula,
				valor_total: this.fatura.valor_titulo.replace('.', '').replace(',', '.'),
				parcelas: 1,
				hash_cartao: card.hash,
				bandeira: card.cardIcon,
			};

			return this.payAction(data, true);
		},

		async payAction(data, recurrence = false, saveCard = false) {
			try {
				this.$emit('loading', true);

				const url = recurrence
					? '/v1/aluno/pagamentos/processa-pagamento-cartao-recorrente'
					: '/v1/aluno/pagamentos/processa-pagamento-cartao';

				const body = new URLSearchParams(Object.entries(data)).toString();

				const response = await http.post(url, body);

				if (this.emitReturn) {
					const paymentReponse = {
						msg: response.data.message.resp.errorMsg || '',
						success: response.data.message.resp.success || false
					};

					this.$emit('retorno-pagamento', paymentReponse);
					return;
				}

				if (!response.data.message.resp.success) {
					throw Error(response.data.message.resp.errorMsg);
				}

				if (saveCard) {
					this.showSavedCardModal = true;
				}

				this.view = 3;
			} catch (response) {
				if (this.emitReturn) {
					this.$emit('retorno-pagamento', {
						msg: typeof response?.message == 'string' ? response?.message : '',
						success: false
					});
					return;
				}

				let errorMessage = 'Erro ao realizar pagamento. \n Revise os dados e aguarde alguns instantes, caso o erro persista, contate o atendimento. [100]';

				if (typeof response?.message == 'string') {
					errorMessage = response.message;
				}

				this.$store.dispatch('ui/toast/openError', {
					message: errorMessage,
					duration: 8000,
				});

			} finally {
				this.$emit('loading', false);
			}
		},

		async deleteCard() {
			let success = false;

			try {
				this.$emit('loading', true);

				const data = {
					acao_cartao_padrao: 'excluir_cartao',
				};

				const response = await http.post('/v1/aluno/pagamentos/recorrencia-cartao',
					new URLSearchParams(Object.entries(data)).toString(),
				);

				if (
					response.status === 200
					&& response.data.status == 'success'
				) {
					success = true;
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.$emit('loading', false);
			}

			if (success) {
				this.savedCard.hash = '';

				this.view = 2;

				this.savedCard.recurrence = false;
				this.savedCard.lastDigits = '';
				this.savedCard.cardIcon = '';

				this.showDeleteCardModal = false;

				this.$store.dispatch('ui/toast/openSuccess', 'Cartão removido com sucesso!');
			} else {
				this.$store.dispatch('ui/toast/openError', 'Não foi possível remover o cartão, tente novamente ou entre em contato pelo CAA');
			}
		},

		async enableRecurrence() {
			if (this.showRecurrenceCartaoModal == 'info') {
				this.showRecurrenceCartaoModal = false;

				return;
			}

			if (!this.hasSavedCard) {
				this.savedCard.recurrence = true;

				this.showRecurrenceCartaoModal = false;

				return;
			}

			try {
				this.$emit('loading', true);

				await this.$store.dispatch('pagamentos/ativarRecorrencia');

				this.showNoticeRecurrenceEnabled();

				this.savedCard.recurrence = true;
			} catch (error) {
				this.$store.dispatch(
					'ui/toast/openError',
					'Não foi possível ativar a recorrência agora, tente novamente mais tarde'
				);
			} finally {
				this.showRecurrenceCartaoModal = false;

				this.$emit('loading', false);
			}
		},

		showNoticeRecurrenceEnabled() {
			this.$store.dispatch(
				'ui/toast/openSuccess',
				'Pagamento em recorrência ativado'
			);
		},

		shouldShowRecurrenceDisableCartaoModal() {
			if (!this.hasSavedCard) {
				return this.disableRecurrence();
			}

			this.showRecurrenceDisableCartaoModal = true;
		},

		async disableRecurrence() {
			if (!this.hasSavedCard) {
				this.savedCard.recurrence = false;

				this.showRecurrenceDisableCartaoModal = false;

				return;
			}

			try {
				this.$emit('loading', true);

				await this.$store.dispatch('pagamentos/desativarRecorrencia');

				this.showNoticeRecurrenceDisabled();

				this.savedCard.recurrence = false;
			} catch (error) {
				this.$store.dispatch(
					'ui/toast/openError',
					'Não foi possível desativar a recorrência agora, tente novamente mais tarde'
				);
			} finally {
				this.showRecurrenceDisableCartaoModal = false;

				this.$emit('loading', false);
			}
		},

		showNoticeRecurrenceDisabled() {
			this.$store.dispatch(
				'ui/toast/openSuccess',
				'Pagamento em recorrência desabilitado'
			);
		},
	},
}
</script>
